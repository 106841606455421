//#region
import axios from 'axios'
import { url } from '../urls'
import Env_Config from '../settings'

const headers = {
  'Authorization': 'Basic ' + Env_Config.BASIC_AUTH
}

export const getSignature = (params) => {
  let _url = `${url.backendHost}/generateSignature`
  return axios.post(_url, params, { headers })
}

export const getUserZak = (params) => {
  let _url = `${url.backendHost}/zoom/getHostZak`
  return axios.get(_url, { params, headers })
}

export const createBasicUser = (params) => {
  let _url = `${url.backendHost}/zoom/createBasicUser`
  return axios.post(_url, params, { headers })
}

export const getLiveMeetingParticipants = (params) => {
  let _url = `${url.backendHost}/zoom/getLiveMeetingParticipants`
  return axios.get(_url, { params, headers })
}

export const getMeetingDetails = (params) => {
  let _url = `${url.backendHost}/zoom/getMeetingDetails`
  return axios.get(_url, { params, headers })
}