export default {
  api: {
    // url: 'https://test-api-virtualclass.extramarks.com',
    url: 'https://qa-api-virtualclass.extramarks.com',
    mode: 'cors'
  },
  LEAVE_MEETING_URL: 'https://qa-sabtestx.extramarks.com/thank-you-for-joining',
  LEAVE_MEETING_URL_TEACHER: 'https://qa-sabtestx.extramarks.com/thank-you',
  NO_MEETING_DATA_REDIRECT_URL: 'https://sabtestx.extramarks.com/student-dashboard',
  NO_MEETING_DATA_REDIRECT_URL_TEACHER: 'https://sabtestx.extramarks.com/teacher-assesment-dashboard',
}