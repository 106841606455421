import { Navigate, useRoutes } from 'react-router-dom';
import StartMeeting from './pages/meeting/Start';
import JoinMeeting from './pages/meeting/Join';
import SetStartMeetingData from './pages/meeting/SetStartMeetingData';
import SetJoinMeetingData from './pages/meeting/SetJoinMeetingData';


export default function Router() {
    return useRoutes([
        {
            path: '/meetings',
            children: [
                { path: 'join', element: <SetJoinMeetingData /> },
                { path: 'start', element: <SetStartMeetingData /> },
                { path: 'start-class', element: <StartMeeting /> },
                { path: 'join-class', element: <JoinMeeting /> },
                { path: '*', element: <Navigate to="/404" />}
            ]
        }
    ]);
}
