import CryptoJS from "crypto-js";
import Config from '../config/settings'

export const EncryptData = (dataObj) => {
    return CryptoJS.AES.encrypt(JSON.stringify(dataObj), Config.LOCAL_ENCRYPTION_SECRET).toString();
}

export const DecryptData = (ciphertext) => {
    let bytes = CryptoJS.AES.decrypt(ciphertext,Config.LOCAL_ENCRYPTION_SECRET);
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData
}