import React, { useEffect } from 'react';
import { ZoomMtg } from '@zoomus/websdk';
import { getSignature, getUserZak, createBasicUser, getLiveMeetingParticipants } from '../../config/services/config';
import Config from '../../config/settings';
import { DecryptData } from '../../utils/encryptDecrypt';
import('../../../node_modules/@zoomus/websdk/dist/css/bootstrap.css')
import('../../../node_modules/@zoomus/websdk/dist/css/react-select.css')

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.4.5/lib', '/av');    
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');


export default function MeetingJoin() {
    let meetingData = localStorage.getItem('MeetingData')
    let MeetingDataDecrypted = {}
    const password = 'Extra@123'
    const apiKey = 'fDEcFqe3QSiTxjdcZAYW7w'

    if (!meetingData) {
        alert('No Class Data')
        window.location.replace(Config.NO_MEETING_DATA_REDIRECT_URL)
    }
    else {
        MeetingDataDecrypted = DecryptData(meetingData)
    }

    let { meetingId, userName, school_code, uuid, redirectUrl } = MeetingDataDecrypted
    meetingId = meetingId ? JSON.parse(meetingId) : ''

    const zoomMeetingSDK = document.getElementById("zmmtg-root")

    useEffect(() => startMeeting(), [])

    useEffect(() => {
        const unloadCallback = (event) => {
            ZoomMtg.leaveMeeting({
                success: (success) => {
                    console.log("success::", success)
                },
                error: (error) => {
                    console.log("error::", error)
                }
            })
            return "";
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);


    const startMeeting = async () => {
        if (!meetingId) {
            console.error('Meeting Id is missing')
        }
        else if (!userName) {
            console.error('Username is missing')
        }
        else if (!uuid) {
            console.error('uuid is missing')
        }
        else if (!school_code) {
            console.error('school_code is missing')
        }
        else {
            let userEmail = `${uuid}.${school_code}@isv.extramarks.com`
            let resp = await getSignature({ meetingNumber: meetingId, role: 'User' })
            let userCreated = await createBasicUser({ email: userEmail, firstName: userName, uuid, school_code })
            let meetingParticipants = []

            try {
                let getMeetingParticipants = await getLiveMeetingParticipants({ meetingId })

                if (getMeetingParticipants?.data?.meeting?.participants) {
                    meetingParticipants = getMeetingParticipants?.data?.meeting?.participants
                }
            }
            catch (err) {
                console.error(err)
            }

            let userExistInMeeting = meetingParticipants.find(o => o.user_name === userName && !o.leave_time);

            console.log(userExistInMeeting, "::userExistInMeeting");

            if (userExistInMeeting) {
                alert('Student already in class please end previous session before starting a new one!')
                window.location.reload();
            }
            else if (resp.status === 200) {
                let signature = resp.data.signature

                ZoomMtg.init({
                    leaveUrl: redirectUrl ? redirectUrl + "/thank-you-for-joining" : Config.LEAVE_MEETING_URL,
                    screenShare: true,
                    isSupportChat: true,
                    isSupportAV: true,
                    isSupportPolling: true,
                    disableInvite: true,
                    meetingInfo: [
                        'topic',
                        'host',
                        'participant',
                        'enctype',
                        'report'
                    ],
                    success: (success) => {
                        zoomMeetingSDK.style.display = 'block';
                        console.log(success)
                        ZoomMtg.join({
                            signature: signature,
                            meetingNumber: meetingId,
                            userName: userName,
                            apiKey: apiKey,
                            userEmail: userEmail,
                            passWord: password,
                            success: (success) => {
                                console.log("success::", success)
                            },
                            error: (error) => {
                                console.log("error::", error)
                            }
                        })
                    },
                    error: (error) => {
                        console.log(error)
                    }
                })

            }
        }
    }
    return (
        <div>
            <div id="meetingSDKElement">
            </div>
        </div>
    );
}
