import merge from 'lodash/merge';
import local from './local';
import dev from './dev';
import test from './test';
import uat from './uat';
import preProd from './preProd';
import prod from './prod';

let ENV = process.env.REACT_APP_ENV.toLowerCase()

const settings = {
  LOCAL_ENCRYPTION_SECRET: 'Bdeh3#44%dw',
  BASIC_AUTH:'YWRtaW5AZXh0cmE6Ymh3ZTUzMUVEY3MjJGRlZA==',
}

export default (() => {
  switch (ENV) {
    case 'localhost':
    case 'local':
      return merge(local, settings)
    case 'dev':
    case 'development':
      return merge(dev, settings)
    case 'test':
    case 'testing':
      return merge(test, settings)
    case 'uat':
    case 'stage':
    case 'staging':
      return merge(uat, settings)
    case 'preprod':
    case 'preproduction':
      return merge(preProd, settings)
    case 'prod':
    case 'production':
      return merge(prod, settings)
    default:
      return merge(local, settings)
  }
})()