import React, { useEffect } from 'react';
import { ZoomMtg } from '@zoomus/websdk';
import { getSignature, getUserZak } from '../../config/services/config';
import Config from '../../config/settings';
import { DecryptData } from '../../utils/encryptDecrypt';
import '../../../node_modules/@zoomus/websdk/dist/css/bootstrap.css'
import '../../../node_modules/@zoomus/websdk/dist/css/react-select.css'

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.4.5/lib', '/av');    
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');


export default function MeetingStart() {
    let meetingData = localStorage.getItem('MeetingData')
    let MeetingDataDecrypted = {}
    const password = 'Extra@123'
    const apiKey = 'fDEcFqe3QSiTxjdcZAYW7w'

    if (!meetingData) {
        alert('No Class Data')
        window.location.replace(Config.NO_MEETING_DATA_REDIRECT_URL_TEACHER)
    }
    else {
        MeetingDataDecrypted = DecryptData(meetingData)
    }

    let { meetingId, userName, school_code, uuid, redirectUrl } = MeetingDataDecrypted
    meetingId = meetingId ? JSON.parse(meetingId) : ''


    const zoomMeetingSDK = document.getElementById("zmmtg-root")

    useEffect(() => startMeeting(), [])
    useEffect(() => {
        const unloadCallback = (event) => {
            ZoomMtg.leaveMeeting({
                success: (success) => {
                    console.log("leaveMeeting success::", success)
                },
                error: (error) => {
                    console.log("leaveMeeting error::", error)
                }
            })
            return "";
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);


    const startMeeting = async () => {
        if (!meetingId) {
            console.error('meetingId is missing')
        }
        else if (!userName) {
            console.error('username is missing')
        }
        else if (!uuid) {
            console.error('uuid is missing')
        }
        else if (!school_code) {
            console.error('school_code is missing')
        }
        else {
            let resp = await getSignature({ meetingNumber: meetingId, role: 'Host' })
            let userEmail = `${uuid}.${school_code}@isv.extramarks.com`

            if (school_code === "WB0291") {
                userEmail = `${uuid}@extramarks.in`
            }

            let zakToken = await getUserZak({ uuid, school_code })


            // let meetingDetails = null
            // let pastMeetingParticipants = []
            // let attendeeCount = 0

            // try {
            //     let getPastMeetingParticipants = await getLiveMeetingParticipants({ meetingId, meetingType: 'past' })

            //     if (getPastMeetingParticipants?.data?.meeting?.participants) {
            //         pastMeetingParticipants = getPastMeetingParticipants?.data?.meeting?.participants
            //     }

            //     attendeeCount = pastMeetingParticipants?.filter(attndee=>attndee.role !== 'host' ).length
            // }
            // catch (err) {
            //     console.error(err)
            // }

            // console.log(pastMeetingParticipants, "::pastMeetingParticipants");
            // console.log(attendeeCount, "::attendeeCount");

            // let meetingParticipants = []

            // try {
            //     let getMeetingParticipants = await getLiveMeetingParticipants({ meetingId })

            //     if (getMeetingParticipants?.data?.meeting?.participants) {
            //         meetingParticipants = getMeetingParticipants?.data?.meeting?.participants
            //     }
            // }
            // catch (err) {
            //     console.error(err)
            // }

            // let userExistInMeeting = meetingParticipants.find(o => o.user_name === userName && !o.leave_time);

            // console.log(userExistInMeeting, "::userExistInMeeting");


            // if (userExistInMeeting) {
            //     alert('Teacher already in class please end previous session before starting a new one!')
            //     window.location.reload();
            // }
            // else 

            if (resp.status === 200) {
                let signature = resp.data.signature
                let zak = zakToken?.data?.meeting?.token

                ZoomMtg.init({
                    leaveUrl: redirectUrl ? redirectUrl + "/thank-you" : Config.LEAVE_MEETING_URL_TEACHER,
                    isSupportAV: true,
                    isSupportPolling: true,
                    disableInvite: true,
                    meetingInfo: [
                        'host',
                        'participant',
                        'enctype',
                        'report'
                    ],
                    success: (success) => {
                        zoomMeetingSDK.style.display = 'block';

                        ZoomMtg.join({
                            signature: signature,
                            zak,
                            meetingNumber: meetingId,
                            userName: userName,
                            apiKey: apiKey,
                            userEmail,
                            passWord: password,
                            success: (success) => {
                                console.log("success::", success)
                            },
                            error: (error) => {
                                console.log("error::", error)
                            }
                        })
                    },
                    error: (error) => {
                        console.log("error::", error)
                    }
                })

            }
        }
    }


    return (
        <div style={{ display: 'flex' }}>
            <button >Start Meeting</button>
            <div id="meetingSDKElement">
            </div>
        </div>
    );
}
