import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import { EncryptData } from '../../utils/encryptDecrypt';
import Config from '../../config/settings';

export default function MeetingStart() {
    const navigate = useNavigate()
    const search = useLocation().search;
    let meetingId = new URLSearchParams(search).get('meetingId');
    let userName = new URLSearchParams(search).get('userName');
    let uuid = new URLSearchParams(search).get('uuid');
    let school_code = new URLSearchParams(search).get('school_code');
    let redirectUrl = new URLSearchParams(search).get('redirectUrl');

    meetingId = meetingId ? JSON.parse(meetingId) : ''

    useEffect(() => setMeetingDataToLocal(), [])

    const setMeetingDataToLocal = () => {
        localStorage.clear();

        if (!meetingId) {
            console.error('meetingId is missing')
        }
        else if (!userName) {
            console.error('username is missing')
        }
        else if (!uuid) {
            console.error('uuid is missing')
        }
        else if (!school_code) {
            console.error('school_code is missing')
        }

        let meeting_data = {}

        if (meetingId)
            meeting_data.meetingId = meetingId

        if (userName)
            meeting_data.userName = userName

        if (uuid)
            meeting_data.uuid = uuid

        if (school_code)
            meeting_data.school_code = school_code

        if (redirectUrl)
            meeting_data.redirectUrl = redirectUrl

        let EncryptedData = EncryptData(meeting_data)

        localStorage.setItem('MeetingData', EncryptedData)

        navigate('/meetings/start-class')
    }

    return (<div className='start_div'> <h1>Preparing Class....</h1> </div>)
}