export default {
    api: {
      url: 'https://uat-api-virtualclass.extramarks.com',
      mode: 'cors'
    },
    LEAVE_MEETING_URL:'https://uat.extramarks.com/thank-you-for-joining',
    LEAVE_MEETING_URL_TEACHER:'https://uat.extramarks.com/thank-you',
    NO_MEETING_DATA_REDIRECT_URL:'https://teach.extramarks.com/student-dashboard',
    NO_MEETING_DATA_REDIRECT_URL_TEACHER:'https://uat.extramarks.com/teacher-assesment-dashboard',
  }
