import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';


if (!document.getElementById('Meeting-container')) {
  ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.getElementById('root')
  );
} 

window.renderMeeting = (containerId, history) => {
  ReactDOM.render(
    <BrowserRouter history={history} >
      <App history={history} />
    </BrowserRouter>,
    document.getElementById(containerId)
  );
};

window.unmountMeeting = containerId => {
  ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
