export default {
    api: {
        url: 'https://virtualclass-api.extramarks.com',
        // url: 'https://prod-api-virtualclass.extramarks.com',
        mode: 'cors'
    },
    LEAVE_MEETING_URL: 'https://www.extramarks.com/thank-you-for-joining',
    LEAVE_MEETING_URL_TEACHER: 'https://www.extramarks.com/thank-you',
    NO_MEETING_DATA_REDIRECT_URL: 'https://www.extramarks.com/student-dashboard',
    NO_MEETING_DATA_REDIRECT_URL_TEACHER: 'https://www.extramarks.com/teacher-assesment-dashboard',
}
